<template>
  <Modal :options="{width: '80vw'}" @close="$emit('close', true)">
    <div class="title" :class="color">Cobranza # {{ cobranza_id }}</div>
    <div class="body">
      <div class="row">
        <div class="col-sm-12 pb-2">
          <fieldset>
            <legend><b>Detalle de Cobranza</b></legend>

            <ul class="nav nav-tabs mb-3">
              <li class="nav-item info-contacto-item" @click="tabDetalle = 'detalle-cobranza'">
                <div  class="nav-link"  :class="(tabDetalle === 'detalle-cobranza') && 'active'" aria-current="page">Detalle</div>
              </li>
              <li class="nav-item info-contacto-item" @click="tabDetalle = 'historial-cobranza'">
                <div class="nav-link" :class="(tabDetalle === 'historial-cobranza') && 'active'" aria-current="page">Historial</div>
              </li>
            </ul>

            <div v-if="tabDetalle === 'detalle-cobranza'">
              <h5 class="bg-secondary text-white p-1 mb-1">Información general</h5>
              <ul class="list-group">
                <li class="list-group-item py-0 border-0 mb-1">
                  <span class="mr-2">Propietario de cobranza:</span>
                  <b>{{ propietario }}</b>
                </li>
                <li class="list-group-item py-0 border-0 mb-1">
                  <span class="mr-2">Cliente:</span>
                  <b>{{ cobranza.cliente }}</b>
                </li>
                <li class="list-group-item py-0 border-0 mb-1">
                  <span class="mr-2">No. de cuenta:</span>
                  <b>{{ cobranza.no_cuenta }}</b>
                </li>
                <li class="list-group-item py-0 border-0 mb-1">
                  <span class="mr-2">Direccion:</span>
                  <b>{{ cobranza.direccion }}</b>
                </li>
                <li class="list-group-item py-0 border-0 mb-1">
                  <span class="mr-2">Estatus de cobranza:</span>
                  <b>{{ estatus_cobranza(cobranza.estatus_cobranza) }}</b>
                </li>
                <li class="list-group-item py-0 border-0 mb-1">
                  <span class="mr-2">Mora esperada:</span>
                  <b>{{ mora_esperada }}</b>
                </li>
              </ul>

              <h5 class="bg-secondary text-white p-1 mb-1">Información del credito</h5>
              <div class="container-fluid">
                <div class="row justify-content-md-left mb-2">
                  <div class="col-sm-3">
                    <b class="d-block text-secondary">Saldo: </b>
                    <b>${{ moneyFormat(cobranza.saldo) }}</b>
                  </div>
                  <div class="col-sm-3">
                    <b class="d-block text-secondary">Saldo Total: </b>
                    <b>${{ moneyFormat(cobranza.saldo_total) }}</b>
                  </div>
                  <div class="col-sm-3">
                    <b class="d-block text-secondary">Producto: </b>
                    <b>{{ cobranza.producto }}</b>
                  </div>
                  <div class="col-sm-3">
                    <b class="d-block text-secondary">Fecha inicio: </b>
                    <b>{{ cobranza.fecha_inicio }}</b>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-3">
                    <b class="d-block text-secondary">Fecha Vencimiento : </b>
                    <b>{{ cobranza.fecha_vencimiento }}</b>
                  </div>
                  <div class="col-sm-3">
                    <b class="d-block text-secondary">Línea Crédito: </b>
                    <b>${{ moneyFormat(cobranza.linea_credito) }}</b>
                  </div>
                  <div class="col-sm-3">
                    <b class="d-block text-secondary">Estatus del Crédito: </b>
                    <b>{{ cobranza.estatus_credito.label }}</b>
                  </div>
                  <div class="col-sm-3">
                    <b class="d-block text-secondary">Pago mínimo: </b>
                    <b>${{ moneyFormat(cobranza.pago_minimo) }}</b>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-3">
                    <b class="d-block text-secondary">Días de mora : </b>
                    <b>{{ cobranza.dias_mora }}</b>
                  </div>
                  <div class="col-sm-3">
                    <b class="d-block text-secondary">Días de Atraso: </b>
                    <b>{{ cobranza.dias_atraso }}</b>
                  </div>
                  <div class="col-sm-3">
                    <b class="d-block text-secondary">Fondeador: </b>
                    <b>{{ cobranza.fondeador }}</b>
                  </div>
                  <div class="col-sm-3">
                    <b class="d-block text-secondary">Pagos pendientes: </b>
                    <b>{{ cobranza.pagos_pendientes }}</b>
                  </div>
                </div>
              </div>

              <h5 class="bg-secondary text-white p-1 mb-1">Observaciones ({{observaciones.length}})</h5>
              <ul class="list-group list-group-flush observaciones-list" >
                <li v-for="observacion in observaciones"
                    class="list-group-item list-group-item-action">
                  <p class="mb-0 text-bold">
                    <strong class="text-info text-bold">
                      {{observacion.usuario.nombre_completo}}</strong> <span class="badge bg-light text-secondary bg-white text-capitalize">{{$moment(observacion.created_at).format("DD/MM/YYYY hh:mm A.")  }}</span>
                  </p>
                  <i class="text-secondary pl-2">{{ observacion.observacion }}</i>
                </li>
              </ul>

              <h5 class="bg-default text-white p-1 mb-1">Acciones</h5>
              <form class="needs-validation" novalidate>
                <div class="row form-group mt-4">
                  <div class="col-sm-6 col-md-4">
                    <label for="acciones_bpb" class="form-label font-weight-bold">Acción BPB</label>
                    <select name="acciones_bpb" id="acciones_bpb" class="custom-select"
                            v-model="formCobranza.acciones_bpb"
                            :disabled="!cobranza_id">
                      <option :selected="true" :value="null" disabled>Selecciona una opción</option>
                      <option v-for="(item) in cobranza.filtros.acciones_bpb" :key="item" :value="item">{{ item }}</option>
                    </select>
                  </div>
                  <div class="col-sm-6">
                    <label for="acciones_despacho" class="form-label font-weight-bold">Comentarios BPB</label>
                    <div class="input-group">
                    <textarea type="text" name="acciones_despacho" id="acciones_despacho" class="form-control"
                              placeholder="Ingresar acciones del despacho"
                              v-model="formCobranza.acciones_despacho"></textarea>
                    </div>
                  </div>
                </div>
              </form>
            </div>

            <div  v-if="tabDetalle === 'historial-cobranza'">
              <div class="alert alert-light" role="alert" v-if="!cobranza.historico.length">
                No se encontró historial relacionado a la cobranza actual.
              </div>
              <table class="table table-sm border" v-else>
                <thead>
                <tr>
                  <th scope="col">Mes</th>
                  <th scope="col">Cobranza</th>
                  <th scope="col">Estatus de cobranza</th>
                  <th scope="col">Estatus del crédito</th>
                  <th scope="col"></th>
                </tr>
                </thead>
                <tbody>
                <template v-for="(item_historico, index) in cobranza.historico">
                  <tr :key="index">
                    <th scope="row"><span class="text-capitalize">{{ $moment(item_historico.created_at).format("MMMM YYYY") }}</span></th>
                    <th scope="row">Cobranza # {{item_historico.id }}</th>
                    <th scope="row">{{ estatus_cobranza(item_historico.status) }}</th>
                    <th scope="row">{{ item_historico.estatus_core_label }}</th>
                    <th scope="row">
                      <a role="button"
                         data-toggle="collapse"
                         :href="'#historico-'+item_historico.id"
                         aria-expanded="false"
                         :aria-controls="'#historico-'+item_historico.id">Observaciones y comentarios <i class="icon-circle-down"></i></a>
                    </th>
                  </tr>
                  <tr >
                    <td colspan="5" class="collapse border" :id="'historico-'+item_historico.id">
                      <div class="container-fluid">
                        <div class="list-group mt-1">
                          <label class="mb-1 text-secondary"><b>Comentarios BpB:</b></label>
                          <div class="item">
                            <p>{{ !item_historico.acciones_despacho ? "Sin comentarios" : item_historico.acciones_despacho }}</p>
                          </div>

                          <label class="mb-1 text-secondary"><b>Observaciones:</b></label>

                          <ul class="item list-group list-group-flush observaciones-list" >
                            <li v-if="item_historico.observaciones"
                                class="list-group-item list-group-item-action">
                              <p class="mb-0 text-bold">
                                <strong class="text-info text-bold">Observaciones iniciales</strong>
                                <span class="badge bg-light text-secondary bg-white text-capitalize">
                                {{$moment(item_historico.created_at).format("DD/MM/YYYY hh:mm A.")  }}
                              </span>
                              </p>
                              <i class="text-secondary pl-2">{{ item_historico.observaciones }}</i>
                            </li>

                            <li v-for="observacion_historico in item_historico.observaciones_cobranza"
                                class="list-group-item list-group-item-action">
                              <p class="mb-0 text-bold">
                                <strong class="text-info text-bold">
                                  {{observacion_historico.usuario.nombre_completo}}</strong>
                                <span class="badge bg-light text-secondary bg-white text-capitalize">
                                {{$moment(observacion_historico.created_at).format("DD/MM/YYYY hh:mm A.")  }}
                              </span>
                              </p>
                              <i class="text-secondary pl-2">{{ observacion_historico.observacion }}</i>
                            </li>
                          </ul>
                        </div>


                      </div>
                    </td>
                  </tr>
                </template>
                </tbody>
              </table>
            </div>


          </fieldset>
        </div>

        <div class="col-sm-5 mt-3">
          <fieldset>
            <legend><b>Datos de contacto</b></legend>
            <div class="list-group">
              <ul class="list-group list-group-flush contactos">
                <label class="mb-1"><b>Principal</b></label>
                <li class="list-group-item" v-if="cobranza.telefono">
                  <span class="icon-phone"></span>
                  {{cobranza.telefono }}
                </li>
                <li class="list-group-item" v-if="cobranza.correo">
                  <span class="icon-mail"></span>
                  {{ cobranza.correo }}
                </li>
                <template v-if="datos_contacto_hubspot">
                  <label class="mt-2"><b>Adicionales de HubSpot</b></label>
                  <li class="list-group-item" v-if="cobranza.hubspot_data.mobilephone">
                    <span class="icon-phone"></span>
                    {{ cobranza.hubspot_data.mobilephone }}
                    <small class="d-block text-secondary">Número de teléfono móvil.</small>
                  </li>
                  <li class="list-group-item" v-if="cobranza.hubspot_data.work_email">
                    <span class="icon-mail"></span>
                    {{ cobranza.hubspot_data.work_email }}
                    <small class="d-block text-secondary">Correo electrónico del trabajo.</small>
                  </li>
                </template>
              </ul>
              <ul class="list-group list-group-flush contactos" v-if="cobranza.contactos.length">
                <label class="mb-1" >Otros</label>
                <li class="list-group-item" v-for="(contacto, index) in cobranza.contactos" :key="index">
                  <i v-if="contacto.tipo === 'telefono'" class="icon-phone"></i>
                  <i  v-if="contacto.tipo === 'email'" class="icon-mail"></i>
                  <i class="icon-link" v-if="contacto.tipo === 'web'"></i>
                  {{ (contacto.tipo === 'telefono' ? '+52-' : '') + contacto.valor}}
                </li>
              </ul>
            </div>
          </fieldset>
        </div>

        <div class="col-sm-7 mt-3">
          <fieldset>
            <legend><b>Información de contacto</b></legend>
            <ul class="nav nav-tabs">
              <li class="nav-item info-contacto-item" @click="infoContacto = 'llamadas'">
                <div  class="nav-link"  :class="(infoContacto === 'llamadas') && 'active'" aria-current="page">LLamadas ({{cobranza.informacion_seguimiento.llamadas.total}})</div>
              </li>
              <li class="nav-item info-contacto-item" @click="infoContacto = 'correos'">
                <div class="nav-link" :class="(infoContacto === 'correos') && 'active'" aria-current="page">Correos ({{cobranza.informacion_seguimiento.correos.total}})</div>
              </li>
            </ul>

            <ul class="list-group vistas-list list-group-flush rounded-0" v-if="infoContacto === 'llamadas'">
              <li v-for="llamada in cobranza.informacion_seguimiento.llamadas.results"
                  class="list-group-item list-group-item-action">
                <div>
                  <div class="text-right">
                    <span class="badge bg-light text-secondary bg-white text-capitalize">{{$moment(llamada.createdAt).format("LLLL")  }}</span>
                  </div>
                  <p class="mb-0 text-info text-bold">{{ llamada.properties.hs_call_title }}</p>
                  <i class="text-italic pl-2">- {{ llamada.properties.hs_body_preview }}</i>
                </div>
              </li>
            </ul>

            <ul class="list-group vistas-list list-group-flush rounded-0" v-if="infoContacto === 'correos'">
              <li v-for="correo in cobranza.informacion_seguimiento.correos.results"
                  class="list-group-item list-group-item-action">
                <div>
                  <div class="text-right">
                    <span class="badge bg-light text-secondary bg-white text-capitalize">{{$moment(correo.createdAt).format("LLLL")  }}</span>
                  </div>
                  <p class="mb-0 text-bold">
                    <strong class="text-info text-bold">
                      Correo - {{correo.properties.hs_email_subject}}</strong> de {{ correo.properties.hs_email_from_firstname + ' ' + correo.properties.hs_email_from_lastname }}
                    <small class="text-secondary"> ({{correo.properties.hs_email_sender_email}}) </small>
                  </p>
                  <i class="text-secondary pl-2"><strong>Resumen del correo:</strong> {{ correo.properties.hs_body_preview }}</i>
                </div>
              </li>
            </ul>
          </fieldset>
        </div>

        <div class="col-sm-12 mt-3" v-if="cobranza.formularios.length">
          <fieldset>
            <legend class="bg-dark text-white"><b>Promesas de pago</b></legend>

            <div class="container">



              <div class="table-responsive-sm table-sm table-bordered">
                <table class="table">
                  <thead>
                  <tr>
                    <th scope="col">Fecha de creación</th>
                    <th scope="col">Fecha de promesa de pago</th>
                    <th scope="col">Monto promesa de pago</th>
                    <th scope="col">Monto recuperado</th>
                    <th scope="col"></th>
                  </tr>
                  </thead>
                  <tbody>
                  <template v-for="(formulario, index) in cobranza.formularios">
                    <tr :key="index">
                      <th scope="row">{{ $moment(formulario.created_at).format("YYYY-MM-DD HH:mm a") }}</th>
                      <th scope="row">{{ formulario.fecha_promesa_pago }}</th>
                      <th scope="row">$ {{ moneyFormat(formulario.monto_promesa_pago) }}</th>
                      <th scope="row">$ {{ moneyFormat(formulario.monto_recuperado) }}</th>
                      <th scope="row">
                        <a role="button"
                           data-toggle="collapse"
                           :href="'#formulario-'+formulario.id"
                           aria-expanded="false"
                           :aria-controls="'#formulario-'+formulario.id">Ver mas detalles</a>
                      </th>
                    </tr>
                    <tr>
                      <td colspan="5"  class="collapse" :id="'formulario-'+formulario.id">
                        <div class="container-fluid">
                          <div class="row mb-2">
                            <div class="col-sm">
                              <b class="d-block text-secondary">{{ cobranza.listas.motivo_retraso.nombre }}: </b>
                              <p>{{ formulario.motivo_retraso }}</p>
                            </div>
                            <div class="col-sm">
                              <b class="d-block text-secondary">Fecha de regularización: </b>
                              <b>{{ formulario.fecha_regularizacion }}</b>
                            </div>

                            <div class="col-sm-3">
                              <b class="d-block text-secondary">{{ cobranza.listas.mediacion.nombre }}: </b>
                              <b>{{ formulario.mediacion }}</b>
                            </div>
                            <div class="col-sm">
                              <b class="d-block text-secondary">{{ cobranza.listas.apoyo.nombre }}: </b>
                              <b>{{ formulario.apoyo }}</b>
                            </div>
                          </div>
                          <div class="row mb-2">
                            <div class="col-sm">
                              <b class="d-block text-secondary">{{ cobranza.listas.resumen_visita.nombre }}: </b>
                              <p>{{ formulario.resumen }}</p>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </template>
                  </tbody>
                </table>
              </div>
            </div>
          </fieldset>
        </div>

        <div class="col-sm-12 mt-3">
          <fieldset>
            <legend><b>Visitas</b></legend>
            <div class="card text-center border-0" v-if="!cobranza.visitas.data.length">
              <div class="card-body">
                No se encontrarón visitas para mostrar.
              </div>
            </div>
            <div v-else class="row">
              <div class="col-12 mb-4">
                <b class="text-left mr-3">Resumén de visitas:</b>
                <h5 class="d-inline mr-2"><span class="badge bg-secondary text-white">Total: {{cobranza.visitas.data.length}}</span></h5>
                <h5 class="d-inline mr-2"><span class="badge bg-primary text-white">Presenciales: {{cobranza.visitas.visitas_presenciales}}</span></h5>
                <h5 class="d-inline mr-2"><span class="badge bg-warning text-dark">Virtuales pendientes: {{cobranza.visitas.visitas_virtuales.pendientes}}</span></h5>
                <h5 class="d-inline"><span class="badge bg-danger text-white">Virtuales pendientes de agregar resumen de visita: {{cobranza.visitas.visitas_virtuales.pendientes_resumen}}</span></h5>
              </div>
              <div class="col-sm-7">
                <ul class="list-group vistas-list rounded-0">
                  <li v-for="(visita,index) in cobranza.visitas.data"
                      class="list-group-item list-group-item-action"
                      :class="visitaSeleccionada.index === index && 'active'"
                      @click="setVisitaSeleccionada(visita,index)">
                    <span v-if="visita.data.reunion_virtual"
                          class="badge rounded-pill"
                          :class="visitaSeleccionada.index === index ? 'bg-white text-success' : 'bg-success text-white'">
                      <i class="icon-video-camera"></i> Reunión virtual
                    </span>
                    <span v-else
                          class="badge rounded-pill"
                          :class="visitaSeleccionada.index === index ? 'bg-white text-primary' : 'bg-primary text-white'">
                      Visita Presencial
                    </span>
                    <template v-if="visita.tipo === 'evento'">
                      <span v-if="visita.data.pendiente"
                            class="badge rounded-pill"
                            :class="visitaSeleccionada.index === index ? 'bg-white text-warning' : 'bg-warning text-dark'">
                          Pendiente
                    </span>
                      <span v-else
                            class="badge rounded-pill"
                            :class="visitaSeleccionada.index === index ? 'bg-white text-danger' : 'bg-danger text-white'">
                          Pendiente agregar resumen de visita
                    </span>
                    </template>

                    <div>
                      <div class="text-right visita-fecha">
                        <span class="text-white badge badge-secondary text-capitalize">{{$moment(visita.fecha).format("MMMM DD, YYYY hh:mm A")  }}</span>
                      </div>
                      <div v-if="visita.tipo === 'visita'">
                        <h6>Comentarios:</h6>
                        <p>{{ !visita.data.comentarios ? "Sin comentarios" : visita.data.comentarios }}</p>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>

              <div class="col-sm-5">
                <template  v-if="visitaSeleccionada.tipo === 'visita'">

                  <div class="text-right visita-fecha mb-1">
                    <span class="text-white badge badge-success text-capitalize">Visita de {{$moment(visitaSeleccionada.data.created_at).format("LLLL")}}</span>
                  </div>

                  <div class="visor-imagenes text-center">
                    <a class="m-auto w-100" v-if="imagenSleccionada.tipo === 'ubicacion'" :href="visitaSeleccionada.data.url_maps" target="_blank">
                      <img :src="visitaSeleccionada.data.url_map_image" />
                    </a>

                    <img v-else  class="img-fluid visor-imagenes-imagen" :src="imagenSleccionada.url" />
                  </div>


                  <div class="mt-3">
                    <b class="text-left d-block">Ubicación y Evidencias de Visita</b>
                    <img class="img-thumbnail thumbnail-evidencia m-1"
                         :src="visitaSeleccionada.data.url_map_image" @click="verImagen('ubicacion', null)">
                    <img v-for="evidencia_visita in visitaSeleccionada.data.evidencias_visita"
                         :id="evidencia_visita.archivo.id"
                         class="img-thumbnail thumbnail-evidencia m-1"
                         :src="'data:'+evidencia_visita.archivo.type+';base64, '+evidencia_visita.archivo.data" alt=""
                         @click="verImagen('imagen', 'data:'+evidencia_visita.archivo.type+';base64, '+evidencia_visita.archivo.data)">

                  </div>
                </template>

                <template v-if="visitaSeleccionada.data.reunion_virtual">
                  <div class="mt-3">
                    <b class="text-left d-block">Información de Reunión Virtual</b>
                    <ul class="list-group">
                      <li class="list-group-item justify-content-between align-items-center border-0">
                        <h5 class="text-info d-inline mr-2"> <i class="icon-link" /> </h5>
                        <a :href="visitaSeleccionada.data.reunion_virtual.link_evento">Link del evento</a>
                      </li>

                      <li class="list-group-item justify-content-between align-items-center border-0">
                        <h5 class="text-success d-inline mr-2"> <i class="icon-video-camera" /> </h5>
                        <a :href="visitaSeleccionada.data.reunion_virtual.link_reunion">Link de la reunión</a>
                      </li>

                      <li class="list-group-item justify-content-between align-items-center border-0">
                        <h5 class="text-secondary d-inline mr-2"> <i class="icon-calendar" /></h5>
                        <template v-if="$moment(visitaSeleccionada.data.reunion_virtual.inicio).isSame($moment(visitaSeleccionada.data.reunion_virtual.fin), 'day')">
                          {{$moment(visitaSeleccionada.data.reunion_virtual.inicio).format("YYYY-MM-DD hh:mm a") + " - " + $moment(visitaSeleccionada.data.reunion_virtual.fin).format("hh:mm a") }}
                        </template>
                        <template v-else>
                          {{$moment(visitaSeleccionada.data.reunion_virtual.inicio).format("YYYY-MM-DD hh:mm a") + " - " + $moment(visitaSeleccionada.data.reunion_virtual.fin).format("YYYY-MM-DD hh:mm a") }}
                        </template>
                      </li>

                      <li class="list-group-item border-0">
                        <h5 class="text-primary d-inline mr-2"> <i class="icon-users" /> Invitados:</h5>
                        <div>
                          <div class="text-break"><b>Cliente: </b>{{ visitaSeleccionada.data.reunion_virtual.invitado_cobranza }}</div>
                          <div class="text-break"><b>Adicionales: </b>{{ visitaSeleccionada.data.reunion_virtual.invitados }}</div>
                        </div>
                      </li>

                      <li class="list-group-item border-0">
                        <h5 class="d-inline mr-2"> Descripción:</h5>
                        <p class="text-break">{{visitaSeleccionada.data.reunion_virtual.descripcion}}</p>
                      </li>

                    </ul>
                  </div>
                </template>

              </div>
            </div>
          </fieldset>
        </div>

      </div>

    </div>


    <div class="footer">
      <div class="row">
        <div class="col-sm-4">
          <button class="btn complementary-btn" type="button" @click="$emit('close', true)">Cerrar</button>
        </div>
        <div class="col-sm-4 offset-sm-4"><button class="btn secondary-btn" type="button" @click="updateCobranza">Guardar cambios</button></div>
      </div>
    </div>
  </Modal>
</template>

<script>

import Modal from '@/components/Modal'
import utils from '@/helpers/utilidades'
import api from '@/apps/cobranza_admin/api/cobranzas.api'

export default {
  name: "Cobranza",
  components: { Modal},
  data(){
    return {
      infoContacto: 'llamadas',
      visitaSeleccionada: {
        tipo: null,
        fecha: null,
        data: { id: null },
        index: null
      },
      statusColors: {
        default: 'bg-default',
        activa: 'bg-activa',
        cerrada: 'bg-cerrada',
      },
      statusLLamadas:{
        QUEUED:'Conectada',
      },
      formCobranza:{
        acciones_bpb: null,
        acciones_despacho: null,
      },
      imagenSleccionada: {tipo: null, url: null},
      tabDetalle: 'detalle-cobranza'
    }
  },
  props: {
    cobranza_id: null,
    cobranza: {
      id: null,
      formularios: [],
      visitas: {total: null, pendientes: null, pendientes_resumen: null, data: []},
      contactos: [],
      informacion_seguimiento: {
        llamadas: { total: 0, results: [] },
        correos: { total: 0, results: [] }
      },
      acciones_bpb: null,
      acciones_despacho: null,
      filtros: {
        acciones_bpb: []
      },
      evidencias_visita: [],
      observaciones: null,
      observaciones_cobranza: null,
      listas: {
        mediacion: {clave: '', nombre: ''},
        apoyo: {clave: '', nombre: ''},
        resumen_visita: {clave: '', nombre: ''},
        motivo_retraso: {clave: '', nombre: ''}
      },
      historico:[]
    }
  },
  computed: {

    activa() {
     return (this.cobranza_id && !this.cobranza.formulario);
    },
    color() {
      return this.activa ? this.statusColors.activa : this.statusColors.cerrada;
    },
    propietario(){
      return !(this.cobranza.propietario) ? 'Sin asignar' : this.cobranza.propietario.nombre_completo
    },
    estatus_cobranza(){
     return estatus => !(estatus) ? 'Sin estatus asignado' : estatus.nombre
    },
    mora_esperada(){
      if(typeof this.cobranza.mora_esperada == 'number')
        return `${this.moneyFormat(this.cobranza.mora_esperada)}`;
      else
        return !this.cobranza.mora_esperada  ? 'Sin registro' : this.cobranza.mora_esperada_label;
    },
    datos_contacto_hubspot(){
      return this.cobranza.hubspot_data && (this.cobranza.hubspot_data.mobilephone || this.cobranza.hubspot_data.work_email);
    },
    observaciones(){
      let observaciones = (this.cobranza.observaciones_cobranza !== null) ? this.cobranza.observaciones_cobranza : [];
      if (this.cobranza.observaciones){
        observaciones.push({
          id: 0,
          observacion: this.cobranza.observaciones,
          created_at: this.cobranza.created_at,
          usuario: {nombre_completo: 'Observaciones iniciales'}
        })
      }
      return observaciones.sort((itemA,itemB)=>itemA.created_at-itemB.created_at);
    }
  },
  mounted() {
    if(this.cobranza.visitas.data.length > 0){
      this.setVisitaSeleccionada(this.cobranza.visitas.data[0], 0);
    }
    this.formCobranza.acciones_bpb = this.cobranza.acciones_bpb;
    this.formCobranza.acciones_despacho = this.cobranza.acciones_despacho;
  },
  methods: {
    moneyFormat(value){
      return  utils.moneyFormat(value);
    },
    setVisitaSeleccionada(visita, index){
      this.visitaSeleccionada = visita;
      this.visitaSeleccionada.index = index;
      if (visita.tipo === 'visita' ) {
        this.visitaSeleccionada.data.url_maps = `https://www.google.com/maps/search/${visita.data.latitud},${visita.data.longitud}`;
        this.visitaSeleccionada.data.url_map_image = `https://maps.googleapis.com/maps/api/staticmap?size=370x200&markers=
      ${visita.data.latitud},${visita.data.longitud}&key=${process.env.VUE_APP_API_KEY_MAPS}&scale=1`;
        this.imagenSleccionada.tipo = 'ubicacion';
      }
    },
    async updateCobranza() {
      try {
        await api.update(this.cobranza_id, this.formCobranza);
      } finally {
        this.$helper.showMessage('Información guardada con exito');
      }
    },
    verImagen(tipo, url){
      this.imagenSleccionada.tipo = tipo;
      this.imagenSleccionada.url = url;
    },


    observaciones_list(cobranza) {
      let observaciones = cobranza.observaciones_cobranza;
      if (cobranza.observaciones) {
        observaciones.push({
          id: 0,
          observacion: cobranza.observaciones,
          created_at: cobranza.created_at,
          usuario: {nombre_completo: 'Observaciones iniciales'}
        })
      }
      return observaciones.sort((itemA, itemB) => itemA.created_at - itemB.created_at);
    },
  }
}
</script>

<style scoped lang="scss">
.bg-default {
background-color: #1976d2 !important;
}
.bg-activa {
background-color: #4caf50 !important;
}
.bg-cerrada {
background-color: #ff9800 !important;
}

.contactos{
  font-size: small;
  max-height: 280px;
  overflow-x: hidden;
}

legend{
  font-size: 20px;
}

.vistas-list{
  height: 224px;
}

.observaciones-list{
  font-size: 14px;
  height: 120px;
}

.vistas-list, .observaciones-list{
  overflow-y: scroll;
  overflow-x: hidden;

  li:hover{
    cursor: pointer;
  }

  li {
    .visita-fecha > span {
      background: #e3e3e3;
      color: #716e6e !important;
    }
  }

  li.active {
    p{
      color: white;
    }
    .visita-fecha > span {
      background: white;
      color: #716e6e !important;
    }
  }
}

.info-contacto-item{
  cursor: pointer;
}

#accion_despacho{
  resize: none;
}
.thumbnail-evidencia{
  height: 50px;
  width: 50px;
  object-fit: cover;
  opacity: 0.8;
  cursor: pointer;
}
.thumbnail-evidencia:hover {
  opacity: 1;
  border-color: #0E608D;
}
.visor-imagenes{
  position: relative;
  object-fit: cover;
  height: 250px;
  display: flex;
  background-color: #f2f2f2;

  .visor-imagenes-imagen{
    width: auto;
    height: 100%;
    margin: auto;
  }
}



</style>
